import type { FC } from 'react';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import type { AnnotationUpdateEmitter } from '@atlaskit/editor-common/annotation';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/standard-button';
import CommentIcon from '@atlaskit/icon/core/migration/comment';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import { SSRMouseEventWrapper, SSR_COMMENT_BUTTON_METRIC } from '@confluence/browser-metrics';
import {
	GeneralShortcutListener,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER,
	ShortcutVisualizer,
} from '@confluence/shortcuts';
import {
	UnreadCommentCount,
	useUnreadInlineComments,
	UnreadTooltipText,
} from '@confluence/unread-comments';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		minWidth: '30px',
		paddingLeft: token('space.025', '2px'),
		paddingRight: token('space.025', '2px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentCountWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

type CommentButtonPlaceholderProps = {
	isDisabled?: boolean;
	handleCommentButtonClick?: (evt: any) => void;
	style?: { padding: string };
	onRender?: () => void;
	contentId?: string;
};

export const CommentButtonPlaceholder: FC<CommentButtonPlaceholderProps> = ({
	isDisabled = true,
	handleCommentButtonClick = () => {},
	style,
	onRender,
	contentId,
}) => {
	useEffect(() => {
		if (!isDisabled) {
			onRender && onRender();
		}
	}, [isDisabled, onRender]);
	const { formatMessage } = useIntl();

	const [{ unreadCommentsListState }] = useUnreadInlineComments();
	const eventEmitter: AnnotationUpdateEmitter = getRendererAnnotationEventEmitter();

	if (isDisabled) {
		return (
			<SSRMouseEventWrapper metricName={SSR_COMMENT_BUTTON_METRIC} isPlaceholder>
				<StyledButton
					testId="comment-button-placeholder"
					data-test-id="comment-button-placeholder"
					appearance="subtle"
					iconBefore={<CommentIcon label="" spacing="spacious" />}
					isDisabled
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={style}
				/>
			</SSRMouseEventWrapper>
		);
	} else {
		return (
			<>
				<GeneralShortcutListener
					accelerator={TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER}
					listener={(e) => {
						if (document.getElementById('renderer-comments-sidebar')) {
							eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
							eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
						} else {
							handleCommentButtonClick(e);
						}
					}}
				/>
				<Tooltip
					content={
						unreadCommentsListState?.length > 0 ? (
							<UnreadTooltipText count={unreadCommentsListState?.length} />
						) : (
							<ShortcutVisualizer
								shortcut={TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER}
								contentBefore={
									<FormattedMessage
										id="comment-button.tooltip"
										defaultMessage="Show comments"
										description="Tooltip for button that shows inline comments for a page"
									/>
								}
							/>
						)
					}
					hideTooltipOnClick
					position="bottom"
				>
					<CommentCountWrapper onClick={handleCommentButtonClick}>
						<StyledButton
							data-id="comment-button-placeholder"
							data-cy="comment-button-placeholder"
							testId="comment-button-placeholder"
							appearance="subtle"
							/* eslint-disable @atlaskit/design-system/no-legacy-icons */
							iconBefore={
								<>
									<CommentIcon
										label={
											unreadCommentsListState?.length > 0
												? formatMessage({
														id: 'comment-button.unread.comments.icon.label',
														description:
															'aria-label for the comment button to display unread inline comments',
														defaultMessage: 'You have unread comments',
													})
												: formatMessage({
														id: 'comment-button.icon.label',
														description:
															'aria-label for the comment button to display the first inline comment',
														defaultMessage: 'Show comments',
													})
										}
										spacing="spacious"
									/>
									<UnreadCommentCount pageId={contentId!} pageMode="view" />
								</>
							}
							/* eslint-enable @atlaskit/design-system/no-legacy-icons */
							isDisabled={isDisabled}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={style}
						/>
					</CommentCountWrapper>
				</Tooltip>
			</>
		);
	}
};
